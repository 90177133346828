.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #50cd89;
}
.swiper-button-next:after {
  color: #50cd89;
  font-size: 15px !important;
}
.swiper-button-prev:after {
  color: #50cd89;
  font-size: 15px !important;
}
